import { observer } from "mobx-react-lite";
import { store } from "../stores/Store";
import { nl2br } from "../utils/Strings";
import { publicUrl } from "../utils/url";

import './ConfigurationSummary.scss'


const ConfigurationSummary = observer(props => {
    const fabric = store.selectedMaterials.find(m => m.category_id === 1)?.material;
    return <div className="ConfigurationSummary">
        <p className="ConfigurationSummary__title">Configuration summary</p>
        <div className="ConfigurationSummary__list">
            {store.selectedMaterials.map(m => {
                const material = m.material;
                return <div key={`${m.category_id}.${m.id}`} className="ConfigurationSummary__list-item">
                    <img src={publicUrl(`/${
                        material.thumbnailParentNameId ?
                            store.selectedMaterials.find(m => m.category_id === material.thumbnailParentNameId)?.material.thumbnail
                            : material.thumbnail}`)} alt="" />
                    <div className="fab-label">{material.category.name}</div>
                    <div className="fab-name">{material.name}</div>
                    <div className="fab-label">Price</div>
                    <div className="fab-name">{(!m.isPremium && !m.isMetalFootrest) ? `Standard (no charge)` : `Premium`}</div>
                    <div className="fab-desc" dangerouslySetInnerHTML={{ __html: nl2br(material.description) }}>

                    </div>
                </div>
            })}
            {/* {fabric &&
                <div className="ConfigurationSummary__list-item">
                    <img src={publicUrl(`/assets/${fabric.thumbnail}`)} alt="" />
                    <div className="fab-label">Fabric</div>
                    <div className="fab-name">{fabric.name}</div>
                    <div className="fab-label">Price</div>
                    <div className="fab-name">{fabric.price === 0 ? `Standard (no charge)` : `Premium (+R 899.00)`}</div>
                    <div className="fab-desc">
                        {fabric.description}
                    </div>
                </div>}

            <div className="ConfigurationSummary__list-item">
                <img src={publicUrl('/assets/th2.png')} alt="" />
                <div className="fab-label">Fabric</div>
                <div className="fab-name">Stained White Oak</div>
                <div className="fab-label">Price</div>
                <div className="fab-name">Premium (+R 899.00)</div>
                <div className="fab-desc">
                    100% Polyester, <br />
                    355gms, <br />
                    Exceeds 40 000 Rubs
                </div>
            </div>

            <div className="ConfigurationSummary__list-item">
                <img src={publicUrl('/assets/th3.png')} alt="" />
                <div className="fab-label">Rattan</div>
                <div className="fab-name">Natural</div>
                <div className="fab-label">Price</div>
                <div className="fab-name">Premium (+R 899.00)</div>
                <div className="fab-desc">
                    100% Polyester, <br />
                    355gms, <br />
                    Exceeds 40 000 Rubs
                </div>
            </div>

            <div className="ConfigurationSummary__list-item">
                <img src={publicUrl('/assets/th4.png')} alt="" />
                <div className="fab-label">Arches</div>
                <div className="fab-name">Copper</div>
                <div className="fab-label">Price</div>
                <div className="fab-name">Premium (+R 899.00)</div>
                <div className="fab-desc">
                    100% Polyester, <br />
                    355gms, <br />
                    Exceeds 40 000 Rubs
                </div>
            </div> */}

        </div>
    </div >
})
ConfigurationSummary.displayName = 'ConfigurationSummary'
export default ConfigurationSummary;


export function formatCurrency(val, {hideBlank = false} = {}){
    const formatter = new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', minimumFractionDigits: 2 });


    if(val > 0 || !hideBlank){
        return formatter.format(val);
        // return `${val} zł`;
    }
    return '';
}


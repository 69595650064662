import { StoreClass } from "./Store";


function post(url, data){
    return fetch(url, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify(data)
    });
}
function get(url){
    return fetch(url, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: "GET",
        // body: JSON.stringify(data)
    });
}

export class CartClass{
    store:StoreClass;

    constructor({store}){
        this.store = store;
    }

    async addToCart(){
        try {
            const response = await post(`${window.Shopify.routes.root}cart/add.js`,{
                items: [
                    {
                        quantity: this.store.quantity,
                        id: this.store.currentVariantId,
                      properties: this.store.propertiesCartMaterials
                    }
                ]
            }).then(r=> {
                window.location.href = '/cart'
            });
        } catch (err) {
            console.error(err)
        }
    }

    async getProduct(handle){
        return get(window.Shopify.routes.root + `products/${handle}.js`).then(r=>r.json());
    }
    
    async getVariantPrice(variantId) {
        let price = 0;
        
        try {
            await get(window.Shopify.routes.root + `variants/${variantId}.js`)
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    price = data.price/100
                })
                .catch((error) => {
                });
        } catch (e) {
            
        }      
      
        return price;
    }
}
import './App.scss';
import { observer } from "mobx-react-lite";
import React from 'react';
import { store } from './stores/Store';
import Loader from './components/Loader';
import { Modal } from './components/Modal';
import SketchfabRender from './components/SketchfabRender';
import ConfigurationSummary from './components/ConfigurationSummary';
import AccordionItem from './components/AccordionItem';
import { publicUrl } from './utils/url';
import InputQty from './components/InputQty';
import { nl2br } from './utils/Strings';


export const PUBLIC_URL = process.env.PUBLIC_URL;

const App = observer(() => {


    // useEffect(() => {
    //     store.loadConfiguration();
    // }, [])

    return (
        <Modal
            className="AppModal"
            isOpen={store.isOpen}
            onClose={e => {
                store.hideModal();
            }}
        >


            <div className="App">
                <Loader fixed={false} zIndex={100} className='Loader__showbox--big' visible={store.pendingRequest} />
                <div className="grid">
                    <div className="grid-render sticky-content--mobile">
                        <div className="sticky-content--desktop">

                            <p className="h1-top">Exclusive Spaces configurator</p>
                            <h1 className="h1">{store.productTitle}</h1>
                            {store.sizes.length > 0 &&
                                <div className="model-sizes">
                                    <div className="model-sizes__label">
                                        Available {store.sizes.some(s => s.name) ? "options" :"sizes" }:
                                    </div>
                                    {store.sizes.map(s => {
                                        return <button key={s.id}
                                            onClick={e => {
                                                store.updateSize(s.id);
                                            }} className={`btn-primary ${s.id === store.selectedSizeId ? ' active' : ''}`}>{s.name?s.name:s.id+'cm'}</button>
                                    })}
                                </div>}
                            <div className="Render">
                                <SketchfabRender />
                            </div>
                            {!store.isMobileDevice &&
                                <ConfigurationSummary />
                            }
                        </div>
                    </div>
                    <div className="grid-summary">
                        {store.isMobileDevice &&

                            <ConfigurationSummary />
                        }
                        <p className="summary-note">Fabric pattern scales may vary slightly. Samples and images available on request. If there is no stock of your selected fabric we will propose similar alternatives for approval.</p>

                    </div>
                    <div className="grid-controls">
                        <div className="AppTabs">
                            {
                                store.categories.map(c => {
                                    return <button key={c.id}
                                        onClick={e => store.selectCategory(c.id)}
                                        className={`AppTab ${store.selectedCategoryId === c.id ? ' active' : ''}`}>{String(c.name).replace(/ /, " ")}</button>

                                })
                            }

                        </div>
                        {
                            store.currentMaterials.map((m, i) => {
                                return <AccordionItem key={m.id}
                                    title={m.name}
                                    controlled={true}
                                    onClick={e => {
                                        store.updateCurrentOpen(m.id);
                                    }}
                                    isOpen={store.currentOpenAccordion === m.id}

                                >
                                    <div className="color-list">

                                        {m.colors.map(c => {
                                            const isSelected = store.isSelected(store.currentCategory.id, c.id);

                                            return <button key={c.id} className={`Color ${isSelected ? ' active' : ''}`}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    store.selectMaterial(store.currentCategory.id, c.id);
                                                }}>
                                                <img src={publicUrl(`/${
                                                    c.thumbnailParentNameId ?
                                                    store.selectedMaterials.find(m => m.category_id === c.thumbnailParentNameId)?.material.thumbnail
                                                    : c.thumbnail}`)} alt="" />
                                                <div className="Color-name"
                                                    dangerouslySetInnerHTML={{ __html: String(c.name).replace(/\//, '/<br/>') }}>

                                                </div>
                                            </button>
                                        })}
                                    </div>
                                </AccordionItem>
                            })
                        }

                        <div className="summary-cart">
                            <div className="sc-price">{store.currentVariantPrice}</div>
                            <div className="sc-add-cart">
                                <div className="sc-add-cart__amount">
                                    <InputQty value={store.quantity} onChange={val => store.updateQty(val)} />
                                </div>
                                <div className="sc-add-cart__btn">
                                    <button disabled={store.quantity === 0}  onClick={e => {
                                        store.cart.addToCart();
                                    }} className="add-to-cart-btn">
                                        ADD TO CART
                                    </button>
                                </div>
                            </div>
                            <div className="sc-info">
                                <a className="sc-info__link" href="mailto:info@exclusivespaces.co.za">Have another fabric/finish in mind? Request a free render</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </Modal>)

})
App.displayName = 'App'


export default App;

import { action,  makeAutoObservable, observable, computed } from "mobx";


const PUBLIC_URL = String(process.env.PUBLIC_URL || '/').replace(/\/$/,'');

class SimpleRouterClass{

    static POP_STATE = 'SR_POP_STATE';
    static PUSH_STATE = 'SR_PUSH_STATE';
    BASE_URL = PUBLIC_URL;
    @observable currentUrl;

    constructor(){
        makeAutoObservable(this);
        window.onpopstate = this.onPopState;
        
        this.currentUrl = this._parseUrl();
    }

    @computed get segment1(){
        return this.getSegment(0);
    }

    _log(){
        console.log(arguments);
    }

    @action setBaseUrl(url){
        this.BASE_URL = url;
    }

    getSegment(index){
        var arr = String(this.currentUrl).replace(/^\//,'').replace(/\/$/,'').split('/');
        if(arr[index]){
            return arr[index];
        }
        return '';
    }

    _parseUrl(){
        return String(window.location.pathname + window.location.search).replace(this.BASE_URL, '');
    }

    @action onPopState = (e) => {
        this._log('onPopState', e);
        const state = e.state;
      
        this.currentUrl = this._parseUrl();

        var e = new Event(self.POP_STATE);
        e.data = {oryginalEvent:e, state: state};
        window.dispatchEvent(e);

    }

    onPushState = ({data, title, url}) => {
        this._log(`onPushState ${self.PUSH_STATE}`);
        
        this.currentUrl = url;


    }


    

    pushState(data = {}, title, url){
        this._log('pushState',arguments);
        history.pushState(data, title, this.BASE_URL + '/' + url);
        
        this.onPushState({data, title, url});
    }
    replaceState(data, title, url){
        this._log('replaceState',arguments);
        history.replaceState(data, title, this.BASE_URL + '/' + url);

        this.onPushState({data, title, url});
        
    }


}


const simpleRouter = new SimpleRouterClass();
window['simpleRouter'] = simpleRouter;

export {
    simpleRouter,
    SimpleRouterClass
}

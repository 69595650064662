

console.log(process.env);
const PUBLIC_URL = process.env.PUBLIC_URL;
const REACT_APP_APS_URL = process.env.REACT_APP_APS_URL;
const REACT_APP_SHOP_DOMAIN = process.env.REACT_APP_SHOP_DOMAIN;
const REACT_APP_PUBLIC = process.env.REACT_APP_PUBLIC;


export function shopUrl(url = ''){
    return REACT_APP_SHOP_DOMAIN + url;

}

export function publicDir(url = ''){
    return publicUrl(url);

}
export function publicUrl(url = ''){
    return REACT_APP_PUBLIC + url;

}
export function absolutePublicUrl(url = ''){
    return REACT_APP_APS_URL + url;
}

export function designFileUrl(id, filePath) {
    return REACT_APP_API_URL + '/design/get/'+id+"/"+filePath;
}


export async function preloadScript(url){
    return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.dataset.cfasync = false;
        script.addEventListener("load", () => {
            console.log(`Script ${url} loaded successfully`);
            resolve();
        });
        script.src = url;
        document.body.appendChild(script);
    });
}

export async function preloadCss(cssUrl){
    return new Promise((resolve, reject) => {
        var head = document.getElementsByTagName('head')[0];
        var s = document.createElement('link');
        s.setAttribute('type', 'text/css');
        s.setAttribute('rel', 'stylesheet');
        s.addEventListener("load", () => {
            console.log(`CSS ${cssUrl} loaded successfully`);
            resolve();
        });
        
        s.setAttribute('href', cssUrl);
        head.appendChild(s);
    });
 }

import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import { preloadCss, preloadScript } from './utils/url';
// import reportWebVitals from './reportWebVitals';

const root = document.createElement('div');
root.setAttribute('id', 'root');
document.body.appendChild(root);

preloadCss('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700&display=swap');
preloadCss('https://configurator.exclusivespaces.shop/static/css/app.css');
preloadScript('https://static.sketchfab.com/api/sketchfab-viewer-1.12.1.js').then(() => {
    
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(<App />);

}).catch(err => {
    console.error(err);
});


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import { computed, makeAutoObservable, observable } from 'mobx';
import { deepCopy } from '../utils/Object.js';
import { publicUrl } from '../utils/url.js';
import { StoreClass } from './Store';



export class SelectedMaterial {
    @observable id;
    @observable category_id;
    store: StoreClass;

    constructor({ id, category_id, store }) {
        makeAutoObservable(this);
        if (!store) {
            throw Error(`store is undefined`);
        }
        this.store = store;
        this.id = id;
        this.category_id = category_id;
    }
    @computed get material() {
        return this.store.materials.find(m => m.id === this.id && m.category_id === this.category_id);
    }
    @computed get isPremium() {
        return Boolean(this.material?.premium || this.material?.material?.premium)
    }
    @computed get isMetalFootrest() {
        return Boolean(this.material?.isMetalFootrest || this.material?.material?.isMetalFootrest)
    }
    @computed get channels() {
        var obj = {
            ...deepCopy(this.material.category.channels),
            ...deepCopy(this.material.material.channels),
            ...deepCopy(this.material.channels),
        };
        for (const channelName in obj) {
            if (obj.hasOwnProperty.call(obj, channelName)) {
                const channel = obj[channelName];
                if (channel.color) {
                    obj[channelName]['color'] = this.store.sf.hexToShetchfabRgb(channel.color);
                }
                if (channel?.texture?.path) {
                    const url = publicUrl(`/${channel.texture.path}`);
                    obj[channelName].texture.path = url;
                    obj[channelName].texture.uid = this.store.sf.getLoadedTextureUid(url);
                }

            }
        }
        return obj;
    }

    async preloadTextures() {
        this.store.showLoader();
        const response = await this.store.sf.loadTextures(this.texturesUrls);
        this.store.hideLoader();
        return response;
    }
    @computed get texturesUrls() {
        var urls = [];
        for (const channelName in this.channels) {
            if (Object.hasOwnProperty.call(this.channels, channelName)) {
                const channel = this.channels[channelName];
                if (channel?.texture?.path) {
                    urls.push(channel.texture.path);
                }

            }
        }
        return urls;
    }
}

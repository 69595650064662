
import './InputQty.scss'

export default function InputQty({ onChange, disabled = false, value = 1 }) {
    var classes = '';

    const decrese = () => {
        if (value < 1) {
            onChange(1);
            return;
        }
        onChange(value - 1);
    }
    const increse = () => {
        onChange(value + 1);
    }


    return <div type='button' className={`InputQty ${classes}`}>
        <button className="btn-minus" disabled={disabled} onClick={decrese}>
            -
        </button>
        <input disabled={disabled} type="text" value={value} onChange={onChange} />
        <button className="btn-plus" disabled={disabled} onClick={increse}>
            +
        </button>
    </div>
}


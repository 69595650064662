import Collapse from "./Collapse";

import './AccordionItem.scss';
import { useState } from "react";
import PlusIcon from "../icons/PlusIcon";
import { useEffect } from "react";

export default function AccordionItem({ children, isOpen = true, title, onClick, controlled = true, interactive = true }) {
    const [open, setOpen] = useState(isOpen);

    useEffect(() => {
        setOpen(isOpen);

    }, [isOpen])

    if (!onClick) {
        onClick = () => {
            if (interactive || !open) {
                setOpen(!open);
            }
        }
    }
    return <div className={`AccordionItem ${open ? 'is-open' : ''}`}>
        <div onClick={onClick} className="AccordionItem__title">
            {title}
            <div className="AccordionItem__plus-icon">
                <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L9 9L17 1" stroke="black" />
                </svg>

            </div>
        </div>
        <Collapse isOpen={open}>

            {children}
        </Collapse>
    </div>
}
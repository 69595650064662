import React, { useRef, useEffect, useState } from "react";
// import "./styles.css";


export default function Collapse({ children, title, isOpen = false, onOpen, onClose }) {
    const [open, setOpen] = useState(isOpen);
    const [animating, setAnimating] = useState(false);
    const [closed, setClosed] = useState(!isOpen);
    const contentRef = useRef(null);

    useEffect(() => {
        setOpen(isOpen);
        setClosed(!isOpen);
    }, [isOpen]);

    useEffect(() => {
        contentRef.current.style.height = open ? `${contentRef.current.scrollHeight}px` : "0px";
        setClosed(false);
        setAnimating(true);


        if (open) {
            if (onOpen) onOpen();
        } else {
            if (onClose) onClose();
        }

        var timerId = setTimeout(() => {
            // if (contentRef.current) {
            //     contentRef.current.style.height = open ? `auto` : '0px';
            // }
            if (!open) {
                setClosed(true);
            }
            setAnimating(false);
        }, 310);
        return () => {
            clearTimeout(timerId);
        }
    }, [open, children]);

    const onClickHandler = () => {
        setOpen(!open);
    };

    const styles = {
        overflow: closed || animating ? 'hidden' : 'visible',
        position: 'relative',
        transition: 'height 0.3s ease-in-out',
    };
    if (open) {
        styles.padding = '1px';
    }

    return (
        <section className={`Collapse ${!isOpen ? 'closed' : 'open'}`}>
            {title &&
                <div className="Collapse__trigger" onClick={onClickHandler}>{title}</div>
            }
            <div style={styles} className={'Collapse__content'} ref={contentRef}>{children}</div>
        </section>
    );
}

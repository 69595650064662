import {deepCopy} from "./Object";

function toRadians(degrees) {
    var pi = Math.PI
    return degrees * (pi / 180)
}

// function getCameraConstraints(){
//     return {
//         "position": [
//           -1.235770470204359,
//           -1.5441004295889378,
//           1.2390354662737546
//         ],
//         "target": [
//           0.0689124260784958,
//           0.11686447029861331,
//           0.5914828781567149
//         ],
//         "fov": 45,
//         "nearFarRatio": 0.005,
//         "useCameraConstraints": true,
//         "usePanConstraints": true,
//         "useZoomConstraints": true,
//         "usePitchConstraints": true,
//         "useYawConstraints": true,
//         "zoomIn": 1.5673016177222578,
//         "zoomOut": 3.4596180838673036,
//         "left": 1.6644929497966974,
//         "right": -1.6644929497966974,
//         "up": 1.555089,
//         "down": -0.14551434605270586
//     };
// }


// https://forum.sketchfab.com/t/sketchfab-pbr-rgb-normalized-issues/10966/2
var gamma = 2.4;

var linearToSrgb = function ( c ) {
    var v = 0.0;
    if ( c < 0.0031308 ) {
        if ( c > 0.0 )
            v = c * 12.92;
    } else {
        v = 1.055 * Math.pow( c, 1.0 / gamma ) - 0.055;
    }
    return v;
};

var srgbToLinear = function ( c ) {
    var v = 0.0;
    if ( c < 0.04045 ) {
        if ( c >= 0.0 )
            v = c * ( 1.0 / 12.92 );
    } else {
        v = Math.pow( ( c + 0.055 ) * ( 1.0 / 1.055 ), gamma );
    }
    return v;
};

var sf;
export default class SketchfabControllerClass {
    sf
    nodes = [];
    materials = [];
    textures = [];
    loadedTextures = {};
    graph;
    uid;
    onClick;
    visibilityState = {};
    constructor({uid, onClick}) {
        this.uid = uid
        this.onClick = onClick
    }

    async init() {
        return new Promise((resolve, reject) => {
            var iframe = document.getElementById('api-frame');
            var uid = this.uid;

            // By default, the latest version of the viewer API will be used.
            var client = new Sketchfab(iframe);
            var self = this;


            // Alternatively, you can request a specific version.
            // var client = new Sketchfab( '1.12.1', iframe );

            client.init(uid, {
                ui_help: 0,
                ui_hint: 0,
                transparent: 0,
                annotation_tooltip_visible: 0,
                annotations_visible: 0,
                annotation:0,
                preload: 0,
                animation_autoplay: 0,
                camera: 0,

                success: function onSuccess(api) {
                    api.start();
                    api.addEventListener('viewerready', async function () {
                        this.sf = api;
                        sf = api;
                        self.sf = api;
                        window.api = sf
                        self.nodes = await self.getNodesMap();
                        self.graph = await self.getSceneGraph();
                        self.materials = await self.getMaterialList();
                        self.textures = await self.getTextureList();
                        self.annotations = await self.getAnnotations();

                        sf.addEventListener('click', function (info) {
                            sf.pickColor(info.position2D, function (results) {
                              var rgba = 'rgba(' + results[0] + ', ' + results[1] + ', ' + results[2] + ', ' + results[3] + ')';
                              console.log('pickColor %c ' + rgba, 'background: ' + rgba + ';');
                            });
                            const node = self.findNodeById(info.instanceID);
                            console.log('click', {info, node});
                            
                            if(self.onClick){
                                self.onClick({info, node});
                            }
                            
                        }, {
                            pick: 'slow'
                        });


                        // API is ready to use
                        // Insert your code here
                        console.log('Viewer is ready');
                        setTimeout(() => {
                            resolve(this)
                        }, 100);

                    });
                },
                error: function onError(err) {
                    console.error('Viewer error',err);

                }
            });
        });
    }

    hexToShetchfabRgb(hexColor){

        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
        const r = srgbToLinear(parseInt(result[1], 16) / 255);
        const g = srgbToLinear(parseInt(result[2], 16) / 255);
        const b = srgbToLinear(parseInt(result[3], 16) / 255);
        return [r, g, b];
    }

    setColor(mat, channelName, hexColor){
        
        mat.channels[channelName].color = this.hexToShetchfabRgb(hexColor);
        return this.setMaterial(mat);
    }


    _findNodeID(name){
        return this.nodes.find(n => String(n.name) === name)?.instanceID;
    }
    _findNodesIDsBegins(name):Array{
        return this.nodes.filter(n => String(n.name).startsWith(name)).map(n=>n.instanceID);
    }
    findNode(name){
        return this.nodes.find(n => String(n.name) === name);
    }
    findNodeById(instanceID){
        return this.nodes.find(n => n.instanceID === instanceID);
    }
    focusOnVisibleGeometries(){

        sf.focusOnVisibleGeometries(function(err) {
            if (!err) {
                window.console.log('Camera recentered');
            }
        });
    }
    recenterCamera(){
        // return new Promise((resolve, reject) => {
            
        //     sf.setEnableCameraConstraints(false, {
        //         preventFocus: false
        //       }, function (err) {
        //         if (err) { reject(err) };
        //         sf.focusOnVisibleGeometries(function (err2) {
        //           if (err2) { reject(err2) };
        //           sf.setEnableCameraConstraints(true, {
        //             preventFocus: false
        //           }, function (err3) {
        //             if (err3) {
        //                 reject(err3);
        //             }else{
        //                 resolve();
        //             }
        //           });
        //         });
        //       });
        // });
        
    }


    translate(name, translate = [1, 1, 1], duration = 0.05){
        return new Promise((resolve, reject) => {
            const instanceID = this._findNodeID(name);
            sf.translate(instanceID, translate, {
                duration: duration,
                easing: 'easeOutQuad'
            }, function(err, translateTo) {
                if (!err) {
                    resolve(translateTo);
                }else{
                    window.console.error('Object has been translated to', {instanceID, translate, err});
                    reject(err);
                }
            })
        });
    }

    
    async setCameraLookAt(cameraPos, targetPos, duration = 0.1){
        cameraPos = deepCopy(cameraPos)
        targetPos = deepCopy(targetPos)
        return new Promise((resolve, reject) => {
            this.setEnableCameraConstraints(false);   
            sf.setCameraLookAt(cameraPos, targetPos, duration, function(err) {
                if (!err) {
                    window.console.log('Camera moved');
                    sf.setCameraLookAtEndAnimationCallback(async function (err) {
                        if (err) console.error(err);
                        console.log('=> Camera End Callback');
                        await this.setEnableCameraConstraints(true);
                        resolve()
                    })
                }
            });
        });
    }
    
    async getCameraLookAt() {
        return new Promise((resolve, reject) => {
            sf.getCameraLookAt(function(err, camera) {
                window.console.log(camera.position); // [x, y, z]
                window.console.log(camera.target); // [x, y, z]
            });

        })
    }

    zoom( factor, duration = 1, minRadius = 1, maxRadius = Infinity ) {
        return new Promise((resolve, reject) => {
            sf.getCameraLookAt( function( err, camera ) {
                if ( !err ) {
                    var currentPos = camera.position,
                        x = currentPos[ 0 ],
                        y = currentPos[ 1 ],
                        z = currentPos[ 2 ],
        
                        target = camera.target,
                        
                        rho = Math.sqrt( ( x * x ) + ( y * y ) + ( z * z ) ),
                        phi,
                        theta;
        
                    if ( isNaN( minRadius ) ) {
                        minRadius = 0.1;
                    }
        
                    if ( isNaN( maxRadius ) ) {
                        maxRadius = Infinity;
                    }
        
                    if ( rho === minRadius || rho === maxRadius ) {
                        return;
                    }
        
                    rho = ( rho * factor );
        
                    if ( rho < minRadius && factor < 0 ) {
                        rho = minRadius;
                    }
        
                    else if ( rho > maxRadius && factor > 0 ) {
                        rho = maxRadius;
                    }
        
                    phi = Math.atan2( y, x );
                    theta = Math.atan2( ( Math.sqrt( ( x * x ) + ( y * y ) ) ), z );
        
                    x = ( rho * Math.sin( theta ) * Math.cos( phi ) );
                    y = ( rho * Math.sin( theta ) * Math.sin( phi ) );
                    z = ( rho * Math.cos( theta ) );
        
                    sf.setCameraLookAt([ x, y, z ], target, duration, function(err) {
                        if (!err) {
                            window.console.log('Camera moved');
                            resolve();
                        }else{
                            reject(err);
                        }
                    });
                }
            });
        });
    }

    async gotoAnnotation(name, animation = true) {
        await this.setEnableCameraConstraints(false);

        return new Promise((resolve, reject) => {
            sf.gotoAnnotation(this.annotations.findIndex(a=>a.name === name), {
                preventCameraAnimation: !animation
            }, async function(err, index){
                // await this.setCameraConstraints(getCameraConstraints());
                // await this.setEnableCameraConstraints(true);

                resolve({err, index})
            })
        });
    }
    getAnnotations() {
        return new Promise((resolve, reject) => {
            sf.getAnnotationList( (p, list) => {
                resolve(list);
            });
        });
    }
    getLoadedTextureUid(name){
        return this.loadedTextures[name];
    }
    /**
     * 
     * @param {String} URL 
     * @returns 
     */
    async addTexture(url, name = null){
        return new Promise((resolve, reject) => {
            sf.addTexture(url, (err, textureUid) => {
                if (!err) {
                    window.console.log('Texture loaded '+ url);
                    if(name){
                        this.loadedTextures[name] = textureUid;
                    }else{
                        this.loadedTextures[url] = textureUid;
                    }
                    resolve(textureUid);
                    
                }else{
                    console.error(err, url, name);
                    reject(err);
                }
            });
        });
    }
    /**
     * 
     * @returns 
     */
    async getTextureList(){
        return new Promise((resolve, reject) => {
            sf.getTextureList((err, textures) =>{
                if (!err) {
                    this.textures = textures;
                    resolve(textures);
                }else{
                    reject(err);
                }
            })
        });
    }
    async loadTextures(urls){
        var promises = urls.map(url_or_object => {
            const { url, name } = url_or_object || {};
            if(url && name && !this.getLoadedTextureUid(name)){
                return this.addTexture(url, name);
            }
            if(!this.getLoadedTextureUid(url_or_object)){
                return this.addTexture(url_or_object);
            }
        });
        return Promise.all(promises);
    }
    /**
     * 
     * @param {{color:Array, uid:String}} options 
     * @returns 
     */
    async setBackground(options){
        return new Promise((resolve, reject) => {
            sf.setBackground(options, function() {
                window.console.log('Background changed');
                resolve(options);
            });
        });
    }
    async getNodesMap(){
        return new Promise((resolve, reject) => {
            sf.getNodeMap(function(err, nodes) {
                if (!err) {
                    var arr = Object.keys(nodes).map(k => nodes[k]);
                    resolve(arr)
                }else{
                    reject(err)
                }
            })
        });
    }
    async getMaterialList(){
        return new Promise((resolve, reject) => {
            sf.getMaterialList(function(err, materials) {
                if (!err) {
                    // this.materials = materials;
                    resolve(materials)
                }
            });
        });
    }
    async assignMaterial(node, materialID){
        return new Promise((resolve, reject) => {
            sf.assignMaterial(node, materialID, function(err) {
                if (!err) {
                    resolve();
                    window.console.log('Material assigned', {node, materialID});
                }else{
                    reject(err);
                }
            });
        });
        
    }
    async assignMaterialToNodes(nameLike, materialID){
        const applyMaterials = (node, materialID) =>{
            if(node.materialID){
                this.assignMaterial(node, materialID);
            }
            if(node.children?.length > 0){
                node.children.forEach(c => applyMaterials(c, materialID) );
            }
        }
        return new Promise((resolve, reject) => {
            this.nodes.forEach(node=> {
                if(String(node.name).startsWith(nameLike)){
                    applyMaterials(node, materialID);
                }
            })
        });
        
    }
    async setMaterial(m){
        return new Promise((resolve, reject) => {
            sf.setMaterial(m, function() {
                resolve(m)
            });
        });
    }
    findMaterialByName(name){
        return this.materials.find(m => m.name === name)
    }
    findTextureByName(name){
        return this.textures.find(m => m.name === name)
    }
    async getSceneGraph(){
        return new Promise((resolve, reject) => {
            sf.getSceneGraph(function(err, graph) {
                if (!err) {
                    
                    resolve(graph)
                }else{
                    reject(err)
                }
            })
        });
    }

    async showNode(name) {
        return new Promise((resolve, reject) => {
            try {
                var id = this._findNodeID(name);
                
                if(this.visibilityState[id] !== 'visible'){
                    sf.show(id);
                    this.visibilityState[id] = 'visible';
                }
                resolve(true)
            } catch (err) {
                console.error(err)
                resolve(false)
            }
        })
    }
    async hideNode(name) {
        return new Promise((resolve, reject) => {
            try {
                var id = this._findNodeID(name);
                if(this.visibilityState[id] !== 'hidden'){
                    sf.hide(id, err => {
                        if(err){
                            console.error(err);
                        }
                    })
                    this.visibilityState[id] = 'hidden';
                }
                resolve(true)
            } catch (err) {
                console.error(err)
                resolve(false)
            }
        })
    }
    async hideChidrenLike(name, like) {
        return new Promise((resolve, reject) => {
            try {
                var node = this.findNode(name);
                var matchingchildren = node.children.filter(c => !!String(c.name).match(like) );
                console.log('hideNode', {name, like, node, matchingchildren});
                matchingchildren.forEach(c=>{
                    sf.hide(c.instanceID);
                })
                resolve(true)
            } catch (err) {
                console.error(err)
                resolve(false)
            }
        })
    }
    async showChidrenLike(name, like) {
        return new Promise((resolve, reject) => {
            try {
                var node = this.findNode(name);
            
                var matchingchildren = node.children.filter(c => !!String(c.name).match(like) );
                console.log('hideNode', {name, like, node, matchingchildren});
                matchingchildren.forEach(c=>{
                    sf.show(c.instanceID);
                })
                
                resolve(true)
            } catch (err) {
                console.error(err)
                resolve(false)
            }
        })
    }
    async hideNodeBegin(name) {
        return new Promise((resolve, reject) => {
            try {
                var ids = this._findNodesIDsBegins(name);
                console.log('hideNode', {name, ids});
                ids.forEach(id=>sf.hide(id));
                resolve(true)
            } catch (err) {
                console.error(err)
                resolve(false)
            }
        })
    }
    async showNodeBegin(name) {
        return new Promise((resolve, reject) => {
            try {
                var ids = this._findNodesIDsBegins(name);
                console.log('showNode', {name, ids});
                ids.forEach(id=>sf.show(id));
                resolve(true)
            } catch (err) {
                console.error(err)
                resolve(false)
            }
        })
    }

    async hideNodes(list = []) {
        return new Promise((resolve, reject) => {
            try {
                list.forEach(name =>{
                    this.hideNode(name);
                })
                // console.log('hideNodes', {list});
                resolve(true)
            } catch (err) {
                console.error(err)
                resolve(false)
            }
        })
    }


    async  showNodes(list = []) {
        return new Promise((resolve, reject) => {
            try {
                list.forEach(name => {
                    this.showNode(name)
                })
                console.log('showNodes', {list});
                resolve(true)
            } catch (err) {
                console.error(err)
                resolve(false)
            }
        })
    }

    // camera


    async setEnableCameraConstraints(bool, options = {preventFocus:false}){
        return new Promise((resolve, reject) => {
            if(!bool){
                options = {};
            }
            sf.setEnableCameraConstraints(bool, options, function(err){
                if(err){
                    console.error(err);
                    reject(err);
                }else{
                    // setTimeout(()=>{
                        resolve();
                    // },1000)
                }
            });
        });
    }
    

    async setCameraConstraints(options) {
        options = deepCopy(options)
        return new Promise((resolve, reject) => {
            sf.setCameraConstraints(options, function(err) {
                if (err) {
                    console.error(err);
                    reject(err);
                }else{
                    resolve(options);
                }
            });
        });
    }; 
    async setEnableCameraConstraints(bool, options) {
        options = deepCopy(options)
        return new Promise((resolve, reject) => {
            sf.setEnableCameraConstraints(bool, options, function(err) {
                if (!err) {
                    window.console.log('Camera constraints enabled');
                }
            });

        });
    };

    async  setFov(angle) {
        return new Promise((resolve, reject) => {
            try {
                sf.setFov(angle, function (err, angle) {
                    if (!err) {
                        window.console.log('FOV set to', angle) // 45
                        resolve(angle)
                    } else {
                        console.error(err)
                    }
                })
                resolve(true)
            } catch (err) {
                console.error(err)
                resolve(false)
            }
        })
    }


    async  setEnvironment({
        rotation = 0,
        enabled = true,
        uid = undefined
    }) {
        const _default = {
            enabled: true,
            exposure: 1.9199,
            lightIntensity: 0.4,
            rotation: 5.672320069,
            shadowEnabled: true
        }

        var options = Object.assign({}, _default, {
            rotation: rotation,
            enabled: enabled,
            uid
        })
        return new Promise((resolve, reject) => {
            try {
                sf.setEnvironment(options, function () {
                    window.console.log('Environment changed')
                    resolve(true)
                })
            } catch (err) {
                console.error(err)
                resolve(false)
            }
        })
    }


    async setUVRotation(mat, channelName, rotation){
        return new Promise((resolve, reject) => {
            sf.setUVRotation(mat, channelName, rotation, (err)=>{
                if(err){
                    reject(err);
                }else{
                    resolve();
                }
            });
            
        });
    }

    async setUVScale(mat, channelName, scaleX, scaleY){
        return new Promise((resolve, reject) => {
            sf.setUVScale(mat, channelName, scaleX, scaleY, (err)=>{
                if(err){
                    reject(err);
                }else{
                    resolve();
                }
            });
            
        });
    }

    async setUVOffset(mat, channelName, offsetX, offsetY){
        return new Promise((resolve, reject) => {
            sf.setUVOffset(mat, channelName, offsetX, offsetY, (err)=>{
                if(err){
                    reject(err);
                }else{
                    resolve();
                }
            });
            
        });
    }



}
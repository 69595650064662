import {
    action,
    computed, makeAutoObservable, observable, toJS
} from 'mobx';
// import config from '../configs_/config.js';
import { formatCurrency } from '../utils/Currency.js';
// import ArManagerClass from '../utils/AR';
import { isIOS, isMobileDevice } from '../utils/Device';
import { delay, throttle } from '../utils/Events';
import { deepCopy } from '../utils/Object.js';
import { simpleRouter, SimpleRouterClass } from '../utils/Router';
import SketchfabControllerClass from '../utils/SketchfabController';
import { confirm } from '../utils/Swal.js';
import { publicUrl } from '../utils/url.js';
import { CartClass } from './Cart.js';
import { SelectedMaterial } from './SelectedMaterial';

const QRCode = require('qrcode')
const BREAKPOINT_XL = 1200;
const PUBLIC_URL = process.env.PUBLIC_URL || '/';

class StoreClass {
    // config = config1
    sf: SketchfabControllerClass;
    cart:CartClass
    
    isAccordionOpenByDefault = window.innerWidth > BREAKPOINT_XL;
    isAccordionInteractive = window.innerWidth < BREAKPOINT_XL;

    // router: SimpleRouterClass
    // project: ProjectClass
    // slotsController: SlotsController
    // cart: CartClass
    // ar: ArManagerClass
    @observable config;
    @observable pendingRequest = true
    @observable currentOpenAccordion = 1
    @action openAccordion(id) {
        this.currentOpenAccordion = id;
    }
    @action updateCurrentOpen(id){
        if(this.currentOpenAccordion === id){
            this.currentOpenAccordion = null;
        }else{
            this.currentOpenAccordion = id;
        }
    }
    @observable isOpen = false
    @action hideLoader(){
        this.pendingRequest = false;
    }
    @action showLoader(){
        this.pendingRequest = true;
    }
    @observable isMobileDevice = isMobileDevice();


   

    @computed get sketchfabVisible(){
        return this.step !== Step.TABLE_TOP;
    }

    @computed get productTitle(){
        var el = document.querySelector('.product_title.entry-title');
        if( el && el.textContent ){
            return el.textContent;
        }
        return this.config?.name;
    }

  
    @observable textures ;

    @observable selectedCategoryId;
    @computed get categories(){
        return this.config?.categories || [];
    }
    @action async selectCategory(id){
        this.selectedCategoryId = id;
        this.openAccordion(store.currentMaterials[0]?.id);
        if(this.config.multiCamera) {
            if(this.currentCategory?.cameraConstraints){
                const {target, position} =  this.currentCategory?.cameraConstraints
                await this.sf.setCameraLookAt(position, target, 0.8);
                // await this.sf.setEnableCameraConstraints(true, this.currentCategory.cameraConstraints);
                await this.sf.setCameraConstraints(this.currentCategory.cameraConstraints);
            } else if(this.config?.cameraConstraints){
                const {target, position} = this.config.cameraConstraints;
                await this.sf.setCameraLookAt(position, target, 0.8);
                // await this.sf.setEnableCameraConstraints(true, this.config.cameraConstraints);
                await this.sf.setCameraConstraints(this.config.cameraConstraints);
            }
        }
       
    }
    
    @computed get currentCategory(){
        return this.categories.find(c => c.id === this.selectedCategoryId)
    };
    @computed get currentMaterials(){
        return this.currentCategory?.materials || []
    };


    @observable selectedMaterials:SelectedMaterial[] = []

    @observable selectedSizeId;
    @computed get sizes(){
        return this.config?.sizes || [];
    }
    @computed get sizeAsOption(){
        const sizes = this.config?.sizes
        if(sizes) {
            
        }
        return this.config?.sizes || [];
    }
    @computed get prices(){
        return this.config?.prices || [];
    }
    @computed get currentVariant(){
        return this.prices.find( p => {
            const hasSizes = this.sizes.length > 0;
            
            if(hasSizes){
                return p.size_id === this.selectedSizeId && p.premium === this.hasPremium && (p.isMetalFootrest === this.hasMetalFootrest || p.isMetalFootrest === undefined);
            }
            return p.premium === this.hasPremium;

        });
    }
    @computed get hasPremium(){
        return this.selectedMaterials.some(m => m.isPremium);
    }
    @computed get hasMetalFootrest(){
        return this.selectedMaterials.some(m => m.isMetalFootrest);
    }
    @computed get currentVariantPrice(){
        if(this.currentVariant){
            return formatCurrency(this.currentVariant.price * this.quantity);
        }
        return '-';
    }

    @computed get currentVariantId(){
        if(this.currentVariant){
            return parseInt(this.currentVariant.product_id)
        }
        return '';
    }
    @computed get selectedSize(){
        return this.sizes.find(s=> s.id === this.selectedSizeId);
    } 
    
    @computed get propertiesCartMaterials(){
        let properties = {}
        this.selectedMaterials.map(m => {
            const material = m.material;
            properties[material.category.name] = material.name
        })
        return properties
    }
    @action updateSize(id){
        this.selectedSizeId = id;
        this.render(['size']);
    }
    

    @computed get materials(){
        const materials = [];
        this.categories.forEach(c => {
            c.materials.forEach(m => {
                m.colors.forEach(color => {
                    materials.push({
                        ...color,
                        material: m,
                        category: c,
                        material_name: c['material_name'],
                        category_id: c.id,
                        accordion_id: m.id,
                        description: m?.description,
                        showNodes: m?.showNodes,
                        hideNodes: m?.hideNodes
                    });
                });
            })
        })
        return materials;
    }

    isSelected(category_id, material_id){
        const found = this.selectedMaterials.find(m => m.id === material_id && m.category_id === category_id);
        return Boolean(found);
    }

    @action async selectMaterial(category_id, material_id){
        const mat = this.materials.find(m => m.category_id === category_id && m.id === material_id);
        const selectedMaterials = this.selectedMaterials.filter(m => m.category_id !== category_id);
        console.log('selectMaterial',{
            category_id,
            material_id,
            mat
        })

        if(mat){
            const s = new SelectedMaterial({ 
                store: this,
                id: mat.id, 
                category_id: category_id
            });
            if(mat.hideNodes) {
                let hideNodes = []
                for (let hideNode of mat.hideNodes) {
                    hideNodes.push(hideNode.name);
                }
                await store.sf.hideNodes(hideNodes);
            }
            if(mat.showNodes) {
                let showNodes = []
                for (let showNode of mat.showNodes) {
                    showNodes.push(showNode.name)
                }
                await store.sf.showNodes(showNodes);
            }
            selectedMaterials.push(s);
            this.selectedMaterials = selectedMaterials;
            await s.preloadTextures();
            // this.sf.loadTextures(s.texturesUrls);

        }


        this.render(['material']);

    }

    @action render(changed = []){

        // sizes
        this.sf.hideNodes(this.sizes.map(n=>n.node));
        if(this.selectedSize){
            this.sf.showNode(this.selectedSize.node);
        }

        if(this.materials.length > 0){
       
            this.selectedMaterials.forEach((s) => {
                const material_name = s.material.material_name;
                const category_id = s.category_id;
                const mat = this.sf.findMaterialByName(material_name);
                const selectedMaterial = this.selectedMaterials.find(m => m.category_id === category_id);
                let UVmap;
                
                if(mat && selectedMaterial){
                    const channels = deepCopy(selectedMaterial.channels);
                    for (const channelName in channels) {
                        if (Object.hasOwnProperty.call(channels, channelName)) {
                            const channel = channels[channelName];
                            const UVmapM = channels[channelName]?.UVmap || {};
                            const UVmapInheritedFromAccordion = mat[channelName]?.UVmap || {};
                            mat.channels[channelName] = {...mat[channelName], ...channel};

                            // apply UVmap
                            const UVmap = {...UVmapInheritedFromAccordion, ...UVmapM};
                          
                            if(UVmap['rotation'] !== undefined){
                                this.sf.setUVRotation(mat, channelName, UVmap['rotation']);
                            }else{
                                this.sf.setUVRotation(mat, channelName, 0);
                            }
                            if(UVmap['scaleX'] !== undefined && UVmap['scaleY'] !== undefined){
                                this.sf.setUVScale(mat, channelName, UVmap['scaleX'], UVmap['scaleY']);
                            }else{
                                this.sf.setUVScale(mat, channelName, 1, 1);
                            }
                            if(UVmap['offsetX'] !== undefined && UVmap['offsetY'] !== undefined){
                                this.sf.setUVRotation(mat, channelName,  UVmap['offsetX'], UVmap['offsetY']);
                            }else{
                                this.sf.setUVRotation(mat, channelName, 0, 0);
                            }
                        
                            
                        }
                    }
                    console.log("Updating material", {
                        mat, 
                        material: selectedMaterial.material, 
                        material_name,
                        channels,
                        UVmap
                    });
                    this.sf.setMaterial(mat);
                    
                }
            });
        }

    }

    

   

    constructor() {
        makeAutoObservable(this)

        this.cart = new CartClass({
            store:this
        });

        
        
        window.addEventListener('resize', this.onResize, { passive: true });
        window.addEventListener('scroll', this.onScroll, { passive: true });
        this.onResize();

        document.addEventListener('click', e=>{
            console.log({
                target: e.target,
                currentTarget: e.currentTarget
            });
            if(e.target && e.target.dataset.confId){
                this.loadConfiguration({id: e.target.dataset.confId});
            }
        });
        setTimeout(()=>{
            // this.loadConfiguration();
            this.onResize();
        },200);
    }

    @observable quantity = 1
    @action updateQty(q){
        this.quantity = q;
    }
    
    @action showError(err){
        this.error = err;
        throw new Error(err);
    }

    showModal(){
        this.isOpen = true;
    }
    hideModal(){
        this.isOpen = false;
    }
    
    @action async selectDefaultMaterials(){
        var promises = [];
        this.selectedCategoryId = this.categories[0].id;
        this.categories.forEach(c => {
            if(c.materials.length > 0){
                const promise = this.selectMaterial(c.id, c.materials[0].colors[0].id);
                promises.push(promise);
            }
        });
        this.updateCurrentOpen(this.currentMaterials[0].id);

        return Promise.all(promises);
    }

    async fetchJSON(name){
        var pubUrl = publicUrl(`/configs/${name}`);
        return fetch(pubUrl).then(r => r.json()).then(json => {
            return json;
        });
    }

    @action async preloadConfiguration(name){
        var materialsSets = {};

        materialsSets['woodMaterials.json'] = await this.fetchJSON('woodMaterials.json');
        materialsSets['rattanMaterials.json'] = await this.fetchJSON('rattanMaterials.json');
        materialsSets['metalMaterials.json'] = await this.fetchJSON('metalMaterials.json');
        materialsSets['fabricMaterials.json'] = await this.fetchJSON('fabricMaterials.json');
        materialsSets['footrestMaterials.json'] = await this.fetchJSON('footrestMaterials.json');
        materialsSets['woodAndMetalMaterials.json'] = await this.fetchJSON('woodAndMetalMaterials.json'); 
        materialsSets['metalAssegaiMaterials.json'] = await this.fetchJSON('metalAssegaiMaterials.json');


        return fetch(publicUrl(`/configs/${name}`)).then(r => r.json()).then(json => {
            json.categories.forEach((m,i)=>{
                json.categories[i].materials = materialsSets[m.material_set_name];
            });
            this.config =  json;
        });
    }

    @action async loadPrice() {
        this.config.prices.forEach((price) => {
          this.cart.getVariantPrice(price.product_id).then(val =>   {price.price = val} )
        })
    }
    
    @action async loadConfiguration({id = null} = {}){
        console.log('loadConfiguration', { id });
        this.pendingRequest = true;
        this.selectedMaterials = [];
        this.selectedCategoryId = null;
        this.selectedSizeId = null;
        this.currentOpenAccordion = null;
        this.config = null;

        try {
            await this.preloadConfiguration(`${id}.json`).catch(e =>{
                console.error(e);
                console.error(`Configuration file ${id}.json not found`);
            });
            
            await this.loadPrice();
            
            this.sf = new SketchfabControllerClass({
                uid: this.config.sketchfabId
            });
            this.showModal();
            await this.sf.init();
            
            // await this.sf.setEnableCameraConstraints(false);
            // await this.sf.setCameraLookAt([-3,-3,2], [0, 0, 0.3]);
            

            if(!this.selectedSize && this.sizes.length > 0){
                this.updateSize(this.sizes[0].id)
            }
            await this.selectDefaultMaterials();
            this.render();

            await delay(200);
            
            if(this.config?.cameraConstraints){
                const {target, position} = this.config.cameraConstraints;
                await this.sf.setCameraLookAt(position, target);
                await this.sf.setCameraConstraints(this.config.cameraConstraints);
            }
            // await this.sf.setCameraLookAt([-2,-2,2], [0, 0, 0.3]);
            // await this.sf.setCameraConstraints({
            //     "position": [
            //         -2,
            //         -2,
            //         2
            //     ],
            //     "target": [
            //         0,
            //         0,
            //         0.3
            //     ],
            //     // "fov": 15,
            //     // "nearFarRatio": 0.005,
            //     // "useCameraConstraints": true,
            //     // "usePanConstraints": true,
            //     "useZoomConstraints": true,
            //     // "usePitchConstraints": true,
            //     // "useYawConstraints": true,
            //     "zoomIn": 1.5,
            //     "zoomOut": 4,
            //     "left": 1.6644929497966974,
            //     "right": -1.6644929497966974,
            //     "up": 1.555089,
            //     "down": -0.14551434605270586
            // });
        } catch (err) {
            console.error(err);
        } finally{
            this.pendingRequest = false;
        }
        
    }


    @action onResize = throttle((e) => {
        const renderTopOffset = document.querySelector('.SketchfabRender')?.getBoundingClientRect()?.top || 0;
        window.document.documentElement.style = `--scroll-y: ${window.scrollY}px; --height-y: ${window.innerHeight}px; --render-top-offset: ${renderTopOffset}px;`;
    
        
        this.isAccordionOpenByDefault = window.innerWidth > BREAKPOINT_XL;
        this.isAccordionInteractive = window.innerWidth < BREAKPOINT_XL;
        this.isMobileDevice = isMobileDevice();
    }, 20)
    @action onScroll = throttle((e) => {
        const renderTopOffset = document.querySelector('.SketchfabRender')?.getBoundingClientRect()?.top || 0;
        window.document.documentElement.style = `--scroll-y: ${window.scrollY}px; --height-y: ${window.innerHeight}px; --render-top-offset: ${renderTopOffset}px;`;
    }, 20)


    
    // AR

    @observable arModalVisible = false;
    @observable arLoading = false;
    @action async onCreateAr(e){
        try {
            this.arLoading = true;
            this.showArModal();
            const response = await this.ar.createAr();
            if(response?.success){
                if(isMobileDevice() || isIOS()){
                    window.location.href = response['url'];
                }else{
                    this.createQrCode(response['url']);
                }
            }

        } catch (err) {
            
        }finally{
            this.arLoading = false;
        }
    }
    @action createQrCode(url){
        const canvas = document.getElementById('QrModalCanvas');

        if(canvas){
            setTimeout(()=>{
                QRCode.toCanvas(canvas, url, {width: 180, height:180}, function (error) {
                    if (error){
                        console.error(error)
                    } else{
                        console.log('QR code created!');
                    }
                });
            },100);
        }

    }
    @action showArModal(){
        this.arModalVisible = true;
    }
    @action hideArModal(){
        this.arModalVisible = false;
    }




}

const store = new StoreClass();
window['store'] = store;
export {
    store,
    StoreClass
};
